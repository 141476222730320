import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeliveryService {
  headers: any;

  constructor(private http: HttpClient, private authService: AuthService) { 
  }

  sendDelivery(delivery: any): Observable<any>{
    this.headers = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': `Bearer ${this.authService.getToken()}`
    })
    return this.http.post(`${environment.BACKEND}/delivery/create`, delivery, {headers: this.headers, observe: 'response'});
  }

  getBusinessPendingDeliveries(page: number): Observable<any>{
    this.headers = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': `Bearer ${this.authService.getToken()}`
    })
    return this.http.get(`${environment.BACKEND}/delivery/business/pending?page=${page}`, {headers: this.headers});
  }

  getBusinessHistoricDeliveries(page: number): Observable<any>{    
    this.headers = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': `Bearer ${this.authService.getToken()}`
    })
    return this.http.get(`${environment.BACKEND}/delivery/business/historic?page=${page}`, {headers: this.headers});
  }

  getHistoricDeliveries(page: number, searchString: string): Observable<any>{    
    this.headers = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': `Bearer ${this.authService.getToken()}`
    })
    return searchString ? 
     this.http.get(`${environment.BACKEND}/delivery/historic?page=${page}&searchString=${searchString}`, {headers: this.headers})
     : this.http.get(`${environment.BACKEND}/delivery/historic?page=${page}`, {headers: this.headers});
  }

  getAllActiveDeliveries(): Observable<any>{
    this.headers = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': `Bearer ${this.authService.getToken()}`
    })
    return this.http.get(`${environment.BACKEND}/delivery/active`, {headers: this.headers});
  }

  getDashboard(): Observable<any>{
    this.headers = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': `Bearer ${this.authService.getToken()}`
    })
    return this.http.get(`${environment.BACKEND}/dashboard`, {headers: this.headers});
  }

  cancelDelivery(delivery: any): Observable<any>{
    console.log(delivery)
    this.headers = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': `Bearer ${this.authService.getToken()}`
    })
    return this.http.delete(`${environment.BACKEND}/delivery/${Number.parseInt(delivery)}`, {headers: this.headers});
  }

  acceptDistributor(delivery: any){
    this.headers = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': `Bearer ${this.authService.getToken()}`
    })
    return this.http.post(`${environment.BACKEND}/delivery/accept-distributor?id=${delivery}`, null, {headers: this.headers});
  }
}
